<template>
  <div
    class="Registro RegistroMain"
    fluid
  >
    <div class="registro-card">
      <v-card outlined>
        <v-card-title v-text="'Registro'" />
        <v-card-text>
          <p>
            Bienvenido a Clínica Dental Master. Para poder brindarte una mejor
            atención y seguimiento es necesario que te registres.
          </p>
          <p>
            Primero, ingresa tu nombre completo y otros datos de identificación
          </p>
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="registro.nombre_pac"
                :error-messages="errors.nombre_pac"
                label="Nombre*"
                filled
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="registro.ape_pat_pac"
                :error-messages="errors.ape_pat_pac"
                label="Apellido paterno*"
                filled
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="registro.ape_mat_pac"
                label="Apellido materno"
                filled
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <DatePicker
                label="Fecha de nacimiento*"
                v-model="registro.fecha_nac_pac"
                :error="errors.fecha_nac_pac"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-select
                v-model="registro.genero_pac"
                label="Género"
                filled
                :items="[
                  { text: 'Femenino', value: 'femenino' },
                  { text: 'Masculino', value: 'masculino' },
                  { text: 'Otro', value: 'otro' },
                  {
                    text: 'Prefiero no especificar',
                    value: 'prefiero no especificar',
                  },
                ]"
              />
            </v-col>
          </v-row>
          <br />
          <p>Ahora ingresa los datos con los cuales podrás iniciar sesión</p>
          <v-row>
            <!-- <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="registro.email_pac"
                :error-messages="errores.email_pac"
                label="Correo electrónico*"
                type="email"
                prepend-inner-icon="email"
                hint="Con tu correo electrónico podrás iniciar sesión"
                persistent-hint
                filled
              />
            </v-col> -->
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="registro.cel_pac"
                :error-messages="errores.cel_pac"
                label="Teléfono móvil*"
                type="tel"
                prepend-inner-icon="phone"
                v-mask="'### ### ####'"
                hint="Con tu teléfono podrás iniciar sesión"
                persistent-hint
                filled
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="registro.password_pac"
                :error-messages="errors.password_pac"
                label="Contraseña*"
                type="password"
                filled
              />
            </v-col>
          </v-row>
          <v-checkbox
            v-model="aceptaTyC"
            :error-messages="errorAceptaTyC"
          >
            <template v-slot:label>
              <div>
                He leído y acepto
                <a
                  target="_blank"
                  href="https://www.nebulajuno.com"
                >
                  los términos y condiciones de uso
                </a>
                así como
                <a
                  target="_blank"
                  href="https://www.nebulajuno.com"
                >
                  la política de privacidad
                </a>
                de Clínica Dental Master*
              </div>
            </template>
          </v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-row dense>
            <v-col
              cols="12"
              offset-sm="3"
              sm="6"
            >
              <v-btn
                :loading="cargando"
                block
                color="primary"
                depressed
                large
                v-text="'Registrarme'"
                @click="_validateAndDoRegistration"
              />
            </v-col>
            <!-- <v-col cols="12" offset-sm="3" sm="6" > <v-facebook-login :app-id="fbid" @sdk-init="handleSdkInit" :login-options="loginOptions" :options="options" @login="_getFbUserData" style="width: 100%" class="mt12" /> </v-col> -->
            <v-col
              cols="12"
              offset-sm="3"
              sm="6"
            >
              <v-btn
                :loading="cargando"
                block
                class="mt12"
                depressed
                large
                text
                v-text="'Ya tengo una cuenta, iniciar sesión'"
                to="/login"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
import fblogin from "@/mixins/fblogin.js";
import DatePicker from "@/components/forms/pickers/DatePicker.vue";
export default {
  name: "RegistroMain",
  mixins: [forms, fblogin],
  components: { DatePicker },
  data() {
    return {
      registro: {
        nombre_pac: null,
        ape_pat_pac: null,
        ape_mat_pac: null,
        fecha_nac_pac: null,
        genero_pac: null,
        email_pac: null,
        cel_pac: null,
        password_pac: null,
      },
      errors: {
        nombre_pac: null,
        ape_pat_pac: null,
        fecha_nac_pac: null,
        password_pac: null,
      },
      errores: {
        // email_pac: null,
        cel_pac: null,
      },
      aceptaTyC: false,
      errorAceptaTyC: null,
      errorTyCTexto:
        "Es necesario haber leído y aceptado los términos y condiciones de uso los términos y condiciones de uso así como la política de privacidad de Clínica Dental Master",
    };
  },
  methods: {
    _validateAndDoRegistration() {
      if (this._validateAll("registro") && this._validateIfHasMailOrPhone()) {
        if (this._aceptoTyC()) {
          this._doRegistration();
        } else {
          this.$emit("msg", this.errorTyCTexto);
        }
      } else {
        this.$emit("msg", this.$lleneCampos);
      }
    },
    _validateIfHasMailOrPhone() {
      if (this.registro.email_pac || this.registro.cel_pac) {
        this.errores.email_pac = null;
        this.errores.cel_pac = null;
        return true;
      } else {
        this.errores.email_pac = "Es necesario llenes uno de estos campos";
        this.errores.cel_pac = "Es necesario llenes uno de estos campos";
        this.$emit(
          "msg",
          "Ingresa tu teléfono celular o correo electrónico para poder registrarte e iniciar sesión"
        );
        return false;
      }
    },
    async _doRegistration() {
      this.cargando = true;
      try {
        const data = { registro: this.registro };
        const { status, body } = await this.$http.post("registro", data);
        this.usuario = this.registro.email_pac;
        this.password = this.registro.password_pac;
        if (status === 201 && "msg" in body) {
          this.$emit("msg", body.msg);
          this._doLogin("login");
        } else {
          this.cargando = false;
          this.$emit("msg", body.error);
        }
      } catch (err) {
        //esto es porque puede que se regrese un error y no sea de servidor, sino de que el usuario ya existe
        "error" in err
          ? this.$emit("msg", err.error)
          : this.$emit("httpError", err);
        this.cargando = false;
      }
    },
    _aceptoTyC() {
      if (this.aceptaTyC) {
        this.errorAceptaTyC = null;
        return true;
      } else {
        const txt = this.errorAceptaTyC;
        this.errorAceptaTyC = txt;
        return false;
      }
    },
  },
  created() {
    this.$emit("appClasses", "");
  },
  beforeDestroy() {
    this.$emit("appClasses", null);
  },
};
</script>
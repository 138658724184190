import VFacebookLogin from "vue-facebook-login-component";
export default {
  components: {
    VFacebookLogin,
  },
  data() {
    return {
      // usuario: '5554656601',
      // password: '123',
      usuario: null,
      password: null,
      cargando: false,
      //fb login
      FB: {},
      model: {},
      scope: {},
      userID: null,
      fbid: "1020148495459253",
      options: { cookie: false, xfbml: true, autoLogAppEvents: true },
      loginOptions: {
        scope: "email,user_birthday,user_gender",
        return_scopes: true,
      },
    };
  },
  methods: {
    handleSdkInit({ FB, scope }) {
      this.FB = FB;
      this.scope = scope;
      this.FB.logout(function(response) {});
    },
    async _getFbUserData(data) {
      this.userID = data.userID;
      let self = this;
      await FB.api(
        "/me",
        {
          fields: [
            "name",
            "first_name",
            "last_name",
            "gender",
            "birthday",
            "email",
          ],
        },
        function(res) {
          const data = {
            method: "facebook",
            data: res,
          };
          self._doLogin("facebook", data);
        }
      );
    },
    async _doFbLoginRegister(datafb) {},
    //code
    async _doLogin(method, datalogin) {
      this.cargando = true;
      try {
        const data =
          method != "login"
            ? datalogin
            : { method: "login", data: { a: this.usuario, b: this.password } };
        const { status, body } = await this.$http.post("login", data);
        if (status === 200 && "token" in body) {
          this.$session.start();
          delete this.$headers.headers.Token;
          this.$headers.headers.Token = body.token;
          this.$session.set("nombre", body.nombre);
          this.$session.set("nombreCompleto", body.nombreCompleto);
          this.$session.set("expediente", body.expediente);
          this.$session.set("token", body.token);
          this.$session.set("tipo", body.tipo);
          this.$session.set("nuevo", body.nuevo);
          this.$session.set("foto", body.foto);
          this.$emit("msg", "Bienvenid@ " + body.nombre);
          this.$router.push("/");
        } else {
          this.$emit("msg", body.error);
        }
        this.cargando = false;
      } catch (err) {
        this.cargando = false;
        this.$emit(
          "msg",
          "Es imposible iniciar sesión por el momento, inténtalo de nuevo más tarde"
        );
      }
    },
  },
  created() {
    this.$session.destroy();
    delete this.$headers.headers.Token;
    delete this.$headers.headers.token;
    this.$emit("visible", false);
    this.$emit("classes", "");
  },
};
